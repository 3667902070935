<template>
  <el-main class="rh-table">
    <h1>Activités hors-clinique</h1>
    <app-table
      tablenom="hcactivites"
      :cols="colonnes"
      :items="hcactivites"
      :loadstatus="hcactivitesLoadStatus"
      :deletestatus="hcactiviteDeleteStatus"
      :formroutes="hcmFormRoutes"
      :actions="hcmActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: "",
      hcmFormRoutes: {
        edit: { route: "hcactiviteedit", params: { idhcm: "id" } },
        create: { route: "hcactivitecreate" },
      },
    };
  },
  computed: {
    hcactivitesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.hcm.hcactivitemedecinsLoadStatus;
    },
    hcactiviteDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.hcm.hcactivitemedecinDeleteStatus;
    },
    hcactivites() {
      return this.$store.state.hcm.hcactivitemedecins;
    },
    colonnes() {
      return this.$store.state.hcm.colonnes;
    },
    hcmActions() {
      return this.$store.state.hcm.actions;
    },
  },
};
</script>
